import { DateTimeFormatter, Duration, Instant, OffsetDateTime } from '@js-joda/core';

const keyofDuration = '$duration';
const keyofInstant = '$instant';

export function internalReplacer(this: any, key: string | number | undefined, value: any): any {
    if (typeof this === 'object' && key !== undefined) value = this[key]; // avoids .toJSON calls

    if (typeof value === 'object' && value instanceof Instant) {
        return { [keyofInstant]: value.toJSON() };
    } else if (typeof value === 'object' && value instanceof Duration) {
        return { [keyofDuration]: value.toJSON() };
    }
    return value;
}

export function internalReviver(key: string | number | undefined, value: any): any {
    if (typeof value === 'object' && value?.[keyofDuration]) return Duration.parse(value[keyofDuration]);
    if (typeof value === 'object' && value?.[keyofInstant]) {
        const odt = OffsetDateTime.parse(value[keyofInstant], DateTimeFormatter.ISO_OFFSET_DATE_TIME);
        return odt.toInstant();
    }
    return value;
}
