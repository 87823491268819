import '../components/table-view/app-wide';
import '../lib/charting';
import '../lib/errorTracking';
import '../lib/i18next';
import '../lib/onesignal';
import '../lib/table';
// Styles
import '../styles/index.scss';
import { container } from 'src/ioc/StaticContainer';
import { localforage } from 'src/lib/serialization/localForage';

// import highcharts from 'highcharts';
// import highchartsTheme from 'highcharts/themes/dark-unica';
// highchartsTheme(highcharts);

const log = container.get('Logger').getSubLogger({ name: 'prelude' });

window.localforage = localforage;
window.logger = log;
