import type { StaticBindings } from '../types';
import type { DatadogService } from '../types/DatadogService';
import type { Logger as LoggerType, LogData } from '../types/Logger';
import type { StatusType } from '@datadog/browser-logs';
import debugFactory from 'debug';
import isEmpty from 'lodash/isEmpty';
import { TypedContainerModule, type TypedContainer } from 'src/features/ioc/TypedContainer';
import { type ILogObjMeta, BaseLogger, type ISettingsParam } from 'tslog';

const LogLevelId = {
    FATAL: 6,
    ERROR: 5,
    WARN: 4,
    INFO: 3,
    DEBUG: 2,
    TRACE: 1,
    SILLY: 0,
} as const;
type LogLevelId = (typeof LogLevelId)[keyof typeof LogLevelId];

interface LogLevel {
    /**
     * Level name, used to map env vars onto a LogLevel
     */
    level: 'silly' | 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal';
    tsLogId: LogLevelId;
    datadogId: StatusType | null;
}

const logLevels: LogLevel[] = [];
logLevels[LogLevelId.SILLY] = {
    level: 'silly',
    tsLogId: LogLevelId.SILLY,
    datadogId: null, // We don't support sending Silly logs to DD
};
logLevels[LogLevelId.TRACE] = {
    level: 'trace',
    tsLogId: LogLevelId.TRACE,
    datadogId: null, // We don't support sending Trace logs to DD
};
logLevels[LogLevelId.DEBUG] = {
    level: 'debug',
    tsLogId: LogLevelId.DEBUG,
    datadogId: 'debug',
};
logLevels[LogLevelId.INFO] = {
    level: 'info',
    tsLogId: LogLevelId.INFO,
    datadogId: 'info',
};
logLevels[LogLevelId.WARN] = {
    level: 'warn',
    tsLogId: LogLevelId.WARN,
    datadogId: 'warn',
};
logLevels[LogLevelId.ERROR] = {
    level: 'error',
    tsLogId: LogLevelId.ERROR,
    datadogId: 'error',
};
logLevels[LogLevelId.FATAL] = {
    level: 'fatal',
    tsLogId: LogLevelId.FATAL,
    datadogId: 'critical',
};

declare global {
    /**
     * The DEBUG filter used to filter local debug messages
     */
    const DEBUG: string | undefined;

    /**
     * The log level identifier which is used to determine what level of log to show in console
     */
    const LOG_LEVEL: LogLevel['level'];

    /**
     * The log level identifier which is used to determine what level of log to send to datadog
     */
    const LOG_LEVEL_DATADOG: LogLevel['level'];
}

/**
 * Determine if a given log level ID should make it to the Debug (console) transport
 */
const shouldLogToDebug = (tsLogId: LogLevelId) => {
    const logLevel = logLevels.find((level) => level.tsLogId === tsLogId);
    if (!logLevel) {
        throw new Error(`Could not find logger match for log level ${tsLogId}`);
        return false;
    }

    const debugLevelId: LogLevelId = logLevels.find((level) => level.level === LOG_LEVEL)?.tsLogId ?? LogLevelId.DEBUG;

    return logLevel.tsLogId >= debugLevelId;
};

/**
 * Determine if a given log level ID should make it to the Datadog transport
 */
const getDatadogLogId = (tsLogId: LogLevelId): LogLevel['datadogId'] | false => {
    const logLevel = logLevels.find((level) => level.tsLogId === tsLogId);
    if (!logLevel?.datadogId) {
        return false;
    }

    const datadogLevel: LogLevel =
        logLevels.find((level) => level.level === LOG_LEVEL_DATADOG) ?? logLevels[LogLevelId.DEBUG];

    if (logLevel.tsLogId >= datadogLevel.tsLogId) {
        return logLevel.datadogId;
    }

    return false;
};

export class Logger extends BaseLogger<LogData> implements LoggerType {
    silly(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(0, 'SILLY', msg, context);
        }
        return super.log(0, 'SILLY', msg);
    }
    trace(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(1, 'TRACE', msg, context);
        }
        return super.log(1, 'TRACE', msg);
    }
    debug(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(2, 'DEBUG', msg, context);
        }
        return super.log(2, 'DEBUG', msg);
    }
    info(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(3, 'INFO', msg, context);
        }
        return super.log(3, 'INFO', msg);
    }
    warn(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(4, 'WARN', msg, context);
        }
        return super.log(4, 'WARN', msg);
    }
    error(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(5, 'ERROR', msg, context);
        }
        return super.log(5, 'ERROR', msg);
    }
    fatal(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(6, 'FATAL', msg, context);
        }
        return super.log(6, 'FATAL', msg);
    }
    getSubLogger(settings?: ISettingsParam<LogData>, logObj?: LogData): Logger {
        return super.getSubLogger(settings, logObj) as Logger;
    }
}

const getName = (message: LogData & ILogObjMeta) => {
    let name = message._meta.name;
    if (!name) {
        name = 'ta:misc';
    } else if (message._meta.parentNames) {
        name = `${message._meta.parentNames.join(':')}:${name}`;
    }

    return name;
};

function debugTransport() {
    const debuggers = new Map<string, ReturnType<typeof debugFactory>>();

    debugFactory.enable(
        `${
            // Defaulting to showing all logs in console, witch a few particularly noisy exceptions.
            // Note that `trace` logs will not show unless running the app with LOG_LEVEL=trace
            DEBUG ?? 'ta:*'
        },-ta:feat:FilterEditor:*`,
    );

    const transport = (msg: LogData & ILogObjMeta) => {
        const shouldLog = shouldLogToDebug(msg._meta.logLevelId as LogLevelId);
        if (!shouldLog) {
            return;
        }

        const name = getName(msg);

        if (!debuggers.has(name)) {
            const debug = debugFactory(name);
            // We special-case these loggers since they print a large token to console, which is very noisy
            if (['ta:push:authentication', 'ta:table:authentication'].includes(name)) {
                debug.log = (...args) => {
                    console.groupCollapsed(name);
                    /* eslint-disable-next-line no-console */
                    console.trace(...args);
                    console.groupEnd();
                };
            }
            debuggers.set(name, debug);
        }

        const { message, _meta: _discarded, ...context } = msg;

        const formatStr = `[${msg._meta.logLevelName}] ${message}`;

        if (isEmpty(context)) {
            debuggers.get(name)?.(formatStr);
        } else {
            debuggers.get(name)?.(`${formatStr} %o`, context);
        }
    };

    return transport;
}

function datadogTransport(datadogLogger: DatadogService['logger']) {
    return (logObj: LogData & ILogObjMeta) => {
        const { message, _meta: _discarded, ...context } = logObj;

        const datadogId = getDatadogLogId(logObj._meta.logLevelId as LogLevelId);

        if (datadogId) {
            datadogLogger.log(message, { component: getName(logObj), ...context }, datadogId);
        }
    };
}

export const logsModule = new TypedContainerModule<StaticBindings>((bind) => {
    bind('Logger').toDynamicValue((ctx) => {
        const container = ctx.container as unknown as TypedContainer<StaticBindings>;

        const transports = [debugTransport()];

        if (container.isBound('DatadogService')) {
            const datadogLogger = container.get('DatadogService').logger;
            transports.push(datadogTransport(datadogLogger));
        }

        return new Logger({
            // Do not ship logs to console using the built-in pretty-printer or JSON format
            type: 'hidden',
            attachedTransports: transports,
            name: 'ta',
        });
    });
});
