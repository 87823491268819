import { Duration, Instant } from '@js-joda/core';
import { QueryClient, type QueryClientConfig } from '@tanstack/query-core';
import { injectable, inject } from 'src/features/ioc';
import type { ReactBindings } from 'src/ioc/types';

export type ThinkAlphaQueryClient = QueryClient & {
    getUserQueryData: QueryClient['getQueryData'];
    setUserQueryData: QueryClient['setQueryData'];
    fetchUserQuery: QueryClient['fetchQuery'];
};

const JITTER_MS = 5000;

export const QueryClientOptions: QueryClientConfig = {
    defaultOptions: {
        queries: {
            refetchOnReconnect: 'always',
            staleTime: Duration.ofDays(1).toMillis(),
            retryDelay: (attemptIndex, _error) =>
                // TODO: Do not retry for some _error
                Math.min(
                    // 2 seconds + jitter
                    1000 * 2 ** attemptIndex + Math.random() * JITTER_MS,
                    // Up to a max of 5 minutes between retries
                    1000 * 60 * 5,
                ),
            retry: Infinity,
        },
    },
};

export function makeUserQueryKey(userId: string | undefined, isSuperuserEnabled: boolean) {
    const userQueryKey = userId
        ? {
              id: userId,
              isSuperuserEnabled,
          }
        : {
              id: null,
              isSuperuserEnabled: false,
          };
    return userQueryKey;
}

@injectable()
export class ThinkAlphaQueryClientImpl extends QueryClient implements ThinkAlphaQueryClient {
    constructor(@inject('Store') private store: ReactBindings['Store']) {
        super(QueryClientOptions);
    }

    getUserQueryData: QueryClient['getQueryData'] = (key, ...params) => {
        const user = this.store.getState().auth.user;
        const superuserStatus = this.store.getState().ui.superuserAccessEnabledUntil;
        const isSuperuserEnabled = superuserStatus !== undefined && superuserStatus.isAfter(Instant.now());
        return this.getQueryData([...key, makeUserQueryKey(user?.id, isSuperuserEnabled)], ...params);
    };

    setUserQueryData: QueryClient['setQueryData'] = (key, ...params) => {
        const user = this.store.getState().auth.user;
        const superuserStatus = this.store.getState().ui.superuserAccessEnabledUntil;
        const isSuperuserEnabled = superuserStatus !== undefined && superuserStatus.isAfter(Instant.now());
        return this.setQueryData([...key, makeUserQueryKey(user?.id, isSuperuserEnabled)], ...params);
    };

    fetchUserQuery: QueryClient['fetchQuery'] = (options, ...params) => {
        const user = this.store.getState().auth.user;
        const superuserStatus = this.store.getState().ui.superuserAccessEnabledUntil;
        const isSuperuserEnabled = superuserStatus !== undefined && superuserStatus.isAfter(Instant.now());
        return this.fetchQuery(
            {
                ...options,
                queryKey: [
                    ...options.queryKey,
                    makeUserQueryKey(user?.id, isSuperuserEnabled) as (typeof options.queryKey)[number],
                ] as any,
            },
            ...params,
        );
    };
}
