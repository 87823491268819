import { appConfig } from '../config/appConfig';

interface CustomWindow extends Window {
    OneSignal: any;
}

declare let window: CustomWindow;

if (typeof window !== 'undefined') {
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(function () {
        window.OneSignal.init({
            appId: appConfig.oneSignalAppId,
        });
    });
}
