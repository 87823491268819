import 'reflect-metadata';
import 'core-js/proposals/explicit-resource-management';
// import 'es6-shim';
// import 'url-search-params-polyfill';
import { installIntoGlobal } from 'iterator-helpers-polyfill';

// Polyfills
try {
    installIntoGlobal();
} catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to install all polyfills', e);
}

/*
if (Error.stackTraceLimit) {
    Error.stackTraceLimit = Infinity;
}
*/
