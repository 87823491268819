import { appConfig } from './appConfig';
import { injectable } from 'inversify';
import { isNative } from 'src/features/native';
import type { ConfigService } from 'src/ioc/types/ConfigService';

@injectable()
export class ConfigServiceImpl implements ConfigService {
    #appConfig = appConfig;

    get launchDarklyConfig(): ConfigService.LaunchDarklyConfig {
        return {
            clientSideId: this.#appConfig.launchDarklyClientSideId,
        };
    }

    get oAuthConfig(): ConfigService.OAuthConfig {
        const fusionAuthURL = this.#appConfig.fusionAuthUrl;
        const tenantID = this.#appConfig.fusionAuthTenantId;
        const applicationID = this.#appConfig.fusionAuthApplicationId;

        if (isNative) {
            return {
                fusionAuthURL,
                tenantID,
                applicationID,
                callbackURL: 'https://dev.thinkalpha.io/oauth2/callback',
                loggedOutURL: 'https://dev.thinkalpha.io/oauth2/loggedout',
            };
        }

        return {
            fusionAuthURL,
            tenantID,
            applicationID,
            callbackURL: `${window.location.origin}/oauth2/callback`,
            loggedOutURL: `${window.location.origin}/oauth2/loggedout`,
        };
    }

    get environmentName() {
        return this.#appConfig.environmentName;
    }

    get commit() {
        return this.#appConfig.commit;
    }

    get documentTitle() {
        return this.#appConfig.documentTitle;
    }
}
