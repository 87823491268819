import { internalReplacer, internalReviver } from './internal';
import localforage from 'localforage';

if (typeof window !== 'undefined') {
    window.localforage = localforage;
}

export type LocalForage = typeof localforage;

function enhanceLocalforage(instance: LocalForage) {
    // double serialization hack here until we have the recursiveWalk function into platform-common
    // although tbh, double serialization probably is faster because it happens in C++
    const localforageWrapper: LocalForage = Object.create(instance);

    localforageWrapper.setItem = function setItem(key, value, callback) {
        value = JSON.parse(JSON.stringify(value, internalReplacer));
        return instance.setItem(key, value, callback);
    };

    localforageWrapper.getItem = function getItem(key, callback) {
        type T = NonNullable<Parameters<NonNullable<typeof callback>>[1]>;
        if (callback) {
            return instance.getItem<T>(key, (err, value) => {
                if (err) return callback(err, value);
                value = JSON.parse(JSON.stringify(value), internalReviver);
                return callback(err, value);
            });
        } else {
            return instance.getItem<T>(key).then((value) => {
                value = JSON.parse(JSON.stringify(value), internalReviver);
                return value;
            });
        }
    };

    return localforageWrapper;
}

const baseInstance = localforage.createInstance({
    name: 'thinkAlphaBaseStorage',
});

const securityMasterInstance = localforage.createInstance({
    name: 'securityMasterStorage',
});

export const securityMasterStorage = enhanceLocalforage(securityMasterInstance);
const baseStorage = enhanceLocalforage(baseInstance);

export { baseStorage as localforage };
