import { Container, ContainerModule, type interfaces } from 'inversify';

export class TypedContainer<Bindings extends object> {
    container: Container;

    constructor(container: Container = new Container({ skipBaseClassChecks: true })) {
        this.container = container;
    }

    bind<T extends keyof Bindings>(key: T): interfaces.BindingToSyntax<Bindings[T]> {
        return this.container.bind(key as string);
    }

    createChild<T extends Bindings>(): TypedContainer<T> {
        return new TypedContainer(this.container.createChild());
    }

    isBound<T extends keyof Bindings>(key: T): boolean {
        return this.container.isBound(key as string);
    }

    get<T extends keyof Bindings>(key: T): Bindings[T] {
        return this.container.get(key as string);
    }

    getOptional<T extends keyof Bindings>(key: T): Bindings[T] | undefined {
        if (this.isBound(key)) {
            return this.get(key);
        }

        return undefined;
    }

    // TODO: It would be great to type this such that the TypedCotnainerModule bindings are a subset of Bindings
    load(module: TypedContainerModule<object>): void {
        this.container.load(module);
    }
}

export class TypedContainerModule<Bindings extends object> extends ContainerModule {
    constructor(
        bindings: (bind: <T extends keyof Bindings>(key: T) => interfaces.BindingToSyntax<Bindings[T]>) => void,
    ) {
        super(bindings as any);
    }
}
