import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';

LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-069169}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{THINKalpha_Technologies_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Alpha}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Alpha}_need_to_be_licensed___{Alpha}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_December_2025}____[v3]_[01]_MTc2NTQ5NzYwMDAwMA==02d222193acb756258931412d691afdd',
);

ModuleRegistry.registerModules([
    // the modules to register
    ClientSideRowModelModule,
    ViewportRowModelModule,
    MenuModule,
    ColumnsToolPanelModule, // TODO: remove after we eliminate the columns tool panel
]);
