import type { WidgetType } from 'src/contracts/workspace';

/*
 * The base error class for UI
 */
abstract class AppError extends Error {
    updateDatadogEvent?(event: any): void;
}

/*
 * All derived error clases
 *
 * Note: for some errors, we want to ensure that some custom info is handled by sentry.
 *
 * To ensure data for a particular error type makes it to Datadog, do the following:
 *  1. Define a custom error class for the error type, taking props in the second argument
 *  2. Assign the props to the instance in the constructor
 *  3. Override the `updateDatadogEvent` method to add the props to the event
 *  4. Add the static `create` method, which has required props (the base class constructor options are optional)
 *  5. Update the eslint `no-restricted-syntax` rule to disallow creating the error instance using `new`
 */

export class UnknownError extends AppError {}

export interface WidgetErrorProps {
    widgetId: string;
    widgetType: WidgetType;
    workspaceId: string;
}
export class WidgetError extends AppError {
    widgetId: string;
    widgetType: string;
    workspaceId: string;

    constructor(message: string, options: { props: WidgetErrorProps }) {
        super(message);

        this.widgetId = options.props.widgetId;
        this.widgetType = options.props.widgetType;
        this.workspaceId = options.props.workspaceId;
    }

    static create(message: string, props: WidgetErrorProps) {
        return new this(message, { props });
    }
}

interface UnexpectedTimestampErrorProps {
    timestamp: string | number;
    cause?: Error;
}
export class UnexpectedTimestampError extends AppError {
    timestamp: string | number;
    cause?: Error;

    constructor(message: string, options: { props: UnexpectedTimestampErrorProps }) {
        super(message);

        this.timestamp = options.props.timestamp;
    }

    static create(message: string, props: UnexpectedTimestampErrorProps) {
        return new this(message, { props });
    }
}
